@import 'styles/all_vars';

.section-product-list {
  z-index: 1;
  padding: 0 !important;

  .eb-ellipsis {
    padding: 0.1rem;
  }

  .item {
    font-size: $size-normal;
    display: block;
    color: $white;
    outline: none !important;
    font-weight: $weight-medium;
    //padding: 0.3rem 0.5rem 0.3rem 1rem;
    padding: 4.8px 8px;

    white-space: nowrap;
    position: relative;
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;

    &.is-not-active {
      color: $grey-dark;
    }

    &.is-active,
    &.is-hover,
    &:hover {
      background-color: $red-dark;
      color: $red-dark-invert;
      border-radius: $radius-large;
    }
  }
}

.section-box.product-module {
  padding-bottom: 0;
  padding-top: 3.5rem;
}

.section-product-page {
  margin-top: -1rem;
  @include mobile() {
    z-index: 3;
    margin-top: 4rem;
  }
}

.product-filter-form--desk {
  padding-right: 3rem;
  padding-left: 1.2rem;

  .field {
    //padding: 0.3rem 1rem;

    .label {
      font-size: 0.6875rem;
    }
  }

  .field .dropdown {
    min-height: 1.7rem;

    .dropdown-menu {
      margin-left: -18px;
      margin-right: -18px;
      right: 0;
    }

    .button {
      font-size: 0.875rem;
    }
  }
}

.page-transition {
  transform: translate(0, 0);
  position: fixed;
  overflow: hidden;
  z-index: 10;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.page-transition--booking {
  position: relative;
  transform: none;
}

.page-transition__shadow {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #000;
  z-index: 9;
}

.page-transition__content--booking {
  position: relative;
  height: 100%;
}

.page-transition-enter-active {
  opacity: 0;

  .page-transition__content {
    transform: translate(100%, 0);
  }

  .page-transition__shadow {
    opacity: 0;
  }

  &.page-transition--booking {
    height: 1000px;
  }
}

.page-transition-enter-done {
  //opacity: 1;
  //transition: opacity 300ms;
  .page-transition__content {
    transform: translate(0, 0);
    transition: all 300ms;
  }

  .page-transition__shadow {
    display: none;
    opacity: 0;
    transition: all 300ms;
  }

  &.page-transition--booking {
    position: fixed;
    @include tablet() {
      position: relative;
    }
  }
}

.page-transition-exit-active {
  //opacity: 0;
  //transition: opacity 300ms;

  .page-transition__content {
    transform: translate(100%, 0);
    transition: all 300ms;
  }
}

.page-transition-exit {
  .page-transition__content {
    transform: translate(100%, 0);
    transition: all 300ms;
  }

  .page-transition__shadow {
    opacity: 0;
    transition: all 300ms;
  }
}

.product-list--enter {
  transform: translate(-15%, 0);
  transition: all 300ms;
}

.product-list--exit {
  transform: translate(0, 0);
  transition: all 300ms;
}

.show-all-resto {
  margin: 20px 0 0;
  text-transform: uppercase;
  font-size: 0.9em;
  font-weight: 500;
}

.show-all-resto__label {
  margin-bottom: 5px;
}
