@import 'styles/all_vars';

//$navMiniFilterHeight: 5.0625rem;
.menu--main {
  z-index: 5 !important;
  //position: relative;
}

#eb-nav-root {
  position: relative;
  z-index: 5;
}

.headroom {
  top: 0;
  left: 0;
  right: 0;
  z-index: 9;
}

.headroom-wrapper {
  z-index: 11;
  //margin-top: $navbar-height;
  @include mobile() {
    //min-height: ($navbar-height * 2) + 1;
  }

  &.hide {
    display: none;
  }
}

.headroom--unfixed {
  position: relative;
  transform: translateY(0);
}

.headroom--scrolled {
  //transition: transform 200ms ease-in-out;
}

.headroom--unpinned {
  position: fixed;
  transform: translateY(-100%);
}

.view-map .headroom,
.headroom--pinned {
  position: fixed;
  transform: translateY(0%);
}

.headroom--filter:not(.headroom--disabled) {
  max-height: $navbar-height;
  min-height: $navbar-height;
}

.headroom--disabled {
  max-height: $navbar-height;
  min-height: $navbar-height;

  .headroom {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    @include tablet() {
      position: relative;
    }
  }
}

.navbar-burger {
  &.is-show {
    display: block;
    width: 2rem;
  }
}

.menu-product-page {
  .navbar-item-logo {
    margin-left: auto;
    @include mobile() {
      margin-right: auto;
    }
  }

  .navbar-burger {
    @include mobile() {
      margin-left: 0;
    }
  }
}

.navbar-item--desktop {
  .eb-circle {
    margin-bottom: 0;
  }
}

.menu-label-grey {
  border: 0 #eeeeee solid;
  border-bottom-width: 1px;
  border-top-width: 1px;
  background-color: #f5f5f5;
  font-size: 0.875rem;
  font-weight: 900;
  color: #000;
  padding: 0.625rem 1.5rem;
  margin: 0;
}

$duration: 200ms;

.fdown-enter {
  opacity: 0;
  transform: translate3d(0, -100%, 0);
}

.fdown-enter-active {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition: transform $duration;
}

.fdown-exit {
  display: none;
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.fdown-exit-active {
  opacity: 1;
  transform: translate3d(0, -100%, 0);
  transition: transform $duration;
}

.button-nav-back {
  cursor: pointer;
  display: block;
  height: 3.0625rem;
  position: relative;
  width: 3.0625rem;
  padding-top: 17px;
  padding-bottom: 17px;
  padding-left: 15px;
}

.nav-circle {
  margin: 0 !important;
  background-color: $white;
  color: $black;
  font-size: 0.875rem;
  line-height: 1.5;
  height: 2rem;
  width: 2rem;
  padding: 0.45rem 0.55rem;
}

.navbar.is-primary .navbar-brand > a.navbar-item:hover {
  background: $primary;
}

.navbar-back {
  display: flex;
  //align-content: center;
  align-items: center;
  justify-content: center;
  width: 60px;
}

.change-locale-box {
  font-size: 1rem;
  padding: 0.938rem 0 0.938rem;
  position: relative;
  border: 0 !important;
  color: $blue;

  a {
    color: $blue;
    display: block;
  }
}
