@import 'styles/all_vars';
@import './swiper.scss';

$dotColorActive: $grey-4;
$dotColorInActive: $grey-1;

.swiper-slide {
  box-shadow: $box-shadow;
  position: relative;
  border-radius: $radius-large;
  display: block;
  overflow: hidden;
}

.ebslider__next,
.ebslider__prev {
  transform: translate3d(0, 0, 0);

  &.swiper-button-disabled {
    opacity: 0.7;
  }
}

.ebslider__headline {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding-bottom: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;
  z-index: 2;
  color: $white;
  font-weight: $weight-medium;
  line-height: 1.3;
  font-size: $size-small;

  .title {
    font-weight: $weight-black;
    font-size: 1.175rem;
    margin-bottom: 0.8rem;
  }

  p {
    margin-bottom: 0.4rem;
  }

  a:hover,
  a:active,
  a:visited,
  a:focus,
  a {
    color: $white;
    font-weight: $weight-bold;
  }
}

.ebslider__shadow {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  opacity: 0.4;
  background: #000;
}

.ebslider__dots {
  position: relative;
  margin-top: 0.5rem;
}

.swiper-pagination-bullet {
  background-color: $dotColorInActive;
  width: 11px;
  height: 11px;
  margin: 7px;
  opacity: 1;
}

.swiper-pagination-bullet-active {
  background-color: $dotColorActive;
}

.ebslider--testimonials {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .ebslider_shadow {
    display: none;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0.5;
  }

  .box-floating {
    //width: 280px;
    display: block;
    box-shadow: none;
  }

  .swiper-container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }

  .swiper-slide {
    width: 280px !important;
    box-shadow: none;
    opacity: 0.5;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    height: auto;

    &.swiper-slide-prev,
    &.swiper-slide-active,
    &.swiper-slide-next {
      opacity: 1;
    }
  }

  .box {
    box-shadow: none;
    padding-left: 0;
    padding-right: 0;
    text-align: center;

    .is-floating {
      width: 100%;
      max-width: 100%;
      left: 0;
      right: 0;
      padding: 0;
    }

    .box-header,
    .content {
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }
}

.info-view-galleri .img-slider {
  height: 100%;
  display: flex;
  align-items: center;
  //margin-top: -32px;
  //@include tablet() {
  //  margin-top: 0;
  //}

  .swiper-wrapper {
    align-items: center;
  }
}
