@import 'styles/all_vars';

.filter-head {
  text-align: center;
  position: relative;
  min-height: 40px;
}

.filter-head__title {
  font-weight: $weight-bold;
  line-height: 1.2;
  padding-left: 5px;
  font-size: 14px;
  text-align: left;
  padding-top: 0;
  padding-right: 0;

  @include tablet() {
    font-size: $size-5-5;
  }
}

.filter-head__filter {
  position: absolute;
  right: 0;
  top: 3px;

  @include tablet() {
    top: 0;
  }

  &--sort {
    top: 3px;
    right: 0;
    z-index: 2;
    @include mobile() {
      z-index: 3;
    }

    @include tablet() {
      right: -30px;
      top: 26px;
    }
  }

  &--desk {
    position: relative;
    text-align: right;
    height: 100%;
    margin-top: 33px;
    margin-right: -2rem;
    z-index: 3;
    padding-left: 10px;
    padding-right: 10px;

    .button {
      padding: 0;
      display: block;
      width: 15px;
      height: 22px;
    }
  }

  &--mob {
  }

  .dropdown-trigger {
    .button {
      border: 0 !important;

      & > span {
        display: none;
      }
    }
  }

  .dropdown {
    display: block;
    width: 100%;

    .button {
      padding-right: 0;
      color: $black;
      font-weight: $weight-black;
      font-size: $size-normal;

      &:focus {
        box-shadow: none;
      }
    }

    .dropdown-item {
      color: $black;
      background-color: $white;

      &:hover,
      &.is-active {
        background-color: whitesmoke;
      }
    }
  }
}
