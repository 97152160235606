$primary: $blue;

// $link-hover: $red;
$info: $cyan;
$success: $green;
$warning: $yellow;
$danger: $red;

$light: $grey-lighter;
$dark: $grey-darker;

// Invert colors

$orange-invert: findColorInvert($orange);
$yellow-invert: findColorInvert($yellow);
$green-invert: findColorInvert($green);
$turquoise-invert: findColorInvert($turquoise);
$cyan-invert: findColorInvert($cyan);
$blue-invert: findColorInvert($blue);
$purple-invert: findColorInvert($purple);
$red-invert: findColorInvert($red);
$red-dark-invert: findColorInvert($red-dark);

$primary-invert: $turquoise-invert;
$info-invert: $cyan-invert;
$success-invert: $green-invert;
$warning-invert: $yellow-invert;
$danger-invert: $red-invert;
$light-invert: $grey-dark;
$dark-invert: $light;

// General colors

$background: $white-ter;

$border: $grey-lighter;
$border-hover: $grey-light;

// Text colors

$text: $black;
$text-invert: findColorInvert($text);
$text-light: $grey-dark;
$text-strong: $black;

// Code colors

$code: $red;
$code-background: $background;

$pre: $text;
$pre-background: $background;

// Link colors

$link: $red;
$link-invert: $red-invert;
$link-visited: $blue;

$link-hover: $red;
$link-hover-border: $grey-light;

$link-focus: $black;
$link-focus-border: $blue;

$link-active: $black;
$link-active-border: $grey-dark;

// Typography

$family-primary: $family-sans-serif;
$family-secondary: $family-sans-serif;
$family-code: $family-monospace;

$size-xsmall: $size-8;
$size-small: $size-7;
$size-normal: $size-6;
$size-medium: $size-5;
$size-large: $size-4;
$size-xlarge: $size-3;

// Lists and maps
$custom-colors: (
  'red-dark': (
    $red-dark,
    $red-dark-invert,
  ),
);

$custom-shades: null;

$colors: mergeColorMaps(
  (
    'white': (
      $white,
      $black,
    ),
    'black': (
      $black,
      $white,
    ),
    'light': (
      $light,
      $light-invert,
    ),
    'dark': (
      $dark,
      $dark-invert,
    ),
    'primary': (
      $primary,
      $primary-invert,
    ),
    'link': (
      $link,
      $link-invert,
    ),
    'info': (
      $info,
      $info-invert,
    ),
    'success': (
      $success,
      $success-invert,
    ),
    'warning': (
      $warning,
      $warning-invert,
    ),
    'danger': (
      $danger,
      $danger-invert,
    ),
    'light-red': (
      $red-light,
      $red-dark,
    ),
    'grey-dark': (
      $grey-dark,
      $black,
    ),
  ),
  $custom-colors
);
$shades: mergeColorMaps(
  (
    'black-bis': $black-bis,
    'black-ter': $black-ter,
    'grey-darker': $grey-darker,
    'grey-dark': $grey-dark,
    'grey': $grey,
    'grey-light': $grey-light,
    'grey-lighter': $grey-lighter,
    'white-ter': $white-ter,
    'white-bis': $white-bis,
    'blue': $blue,
  ),
  $custom-shades
);

$sizes: $size-1 $size-2 $size-3 $size-4 $size-5 $size-6 $size-7 $size-8;
