@import 'styles/all_vars';

$pcardName: 1rem;
.product-card {
  font-weight: $weight-bold;
  border-radius: $radius-large;
  width: 100%;
  color: $black;
  text-transform: uppercase;

  .card-image {
    max-height: 420px;
    overflow: hidden;
    //  img {
    //    padding-top: 56.25%;
    //  }
  }

  &:hover,
  &:focus {
    color: $black;
  }

  &:last-child {
    margin-bottom: 0;
  }

  .title {
    font-size: $pcardName;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    padding-right: 1.5rem;
    margin: 4px 0 0;
  }

  .label {
    font-size: 0.8rem;
  }

  .grid-list {
    width: 100%;
  }

  .show-menu__item,
  .time {
    font-weight: $weight-black;
    color: $grey-dark;
    font-size: 0.7rem;
  }

  .time {
    //height: 1.875rem;
    overflow: hidden;
    font-weight: $weight-medium;
    line-height: 1.3;
    color: $black;
    width: 170px;
  }

  .desc {
    height: 2.4rem;
    margin-bottom: 0.5rem;
    font-weight: $weight-normal;
    line-height: 1.3;
    position: relative;
    overflow: hidden;
    font-size: 0.8rem;
    text-transform: none;
  }

  .show-menu__item {
    text-align: right;
    text-transform: uppercase;
    font-weight: $weight-medium;
    color: $blue;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    min-width: 1px;

    .eb-ellipsis {
      margin-right: -1px;
      //padding-right: 0.8px;
    }

    &.more-menu {
      font-weight: $weight-light;
    }
  }

  .menus {
    .is-flex-mid-col {
      justify-content: flex-start;
    }
  }

  header,
  .product-card__footer {
    padding: 1rem;
  }

  header {
    text-transform: uppercase;
    line-height: 1.3rem;
  }

  .tag-price {
    .normal {
      font-size: 0.8rem;
    }

    .sale {
      font-size: 0.9rem;
    }
  }
}

.product-card__footer {
  font-size: $size-small;
  line-height: 1;
}

.product-card__grid {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-flow: nowrap;
  flex-basis: auto;
}

.product-card--loading {
  //background: rgb(238, 238, 238);
}

.item--bxx {
  .product-card:not(:last-child) {
    margin-bottom: 2rem;
    min-height: 8rem;
  }
}

.product-list-lz {
  overflow-x: hidden;
}

.product-content.v-list {
  //overflow-x: hidden;

  //&:not(.is-hidden-tablet) {
  //  @include mobile() {
  //    display: none;
  //  }
  //}
}
