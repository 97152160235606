@import 'styles/all_vars';

$markerSize: 1.2rem;

#popup-page-map {
  margin-top: 0;
  z-index: 3;
}

.product-map-page {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  min-height: 500px;
  overflow: hidden;
}

$map-board-padding: 1rem;
$map-board-width: 25rem;

.map-board {
  z-index: 3;
  background-color: $white;
  position: absolute;
  bottom: 100%;
  box-shadow: $box-shadow;
  border-radius: $radius-large;
  width: $map-board-width;
  margin-left: -($map-board-width * 0.5);
  font-weight: $weight-bold;
  margin-bottom: 0.9rem;
  cursor: pointer;
  line-height: 1.2;

  &:before {
    content: ' ';
    background-image: url('../../../assets/images/iconsv2/arrow-down.svg');
    background-repeat: no-repeat;
    background-position: left;
    background-size: 2rem;

    position: absolute;
    width: 2rem;
    height: 2rem;
    right: 50%;
    bottom: 0;
    margin-bottom: -1.4rem;
    margin-right: -1.72rem;
  }

  .columns {
    //margin-bottom: 0;
  }

  &__content {
  }

  .title {
    font-size: 1rem;
    padding: $map-board-padding;
    padding-right: 3rem;
    text-transform: uppercase;
    font-weight: $weight-black;
    margin-bottom: 0;
    position: relative;

    @include tablet() {
      font-size: 1.175rem;
    }

    .icon {
      font-size: 0.6rem;
      position: absolute;
      right: 0.7rem;
      top: 50%;
      margin-top: -0.6rem;
    }

    border-bottom: 1px solid $grey;
  }
}

.map-marker {
  z-index: 1;
  border-radius: $radius-rounded;
  width: $markerSize;
  height: $markerSize;
  background: #d40000;
  cursor: pointer;
  border: 0.0625rem solid $grey-1;

  &:before {
    content: ' ';
  }

  .map-marker-in {
    position: absolute;
    border-radius: $radius-rounded;
    border: 2px solid #fff;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: $markerSize;
    height: $markerSize;
  }

  &.is-active {
  }

  &.map--guest {
    background: $blue;
  }
}

.map-board-wrap {
  position: absolute;
  left: 5px;
  right: 5px;
  bottom: 0;
}

.map-board--mobile {
  position: relative;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 77px;
  margin-top: 0;
  width: 100%;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.map-board__content {
  font-weight: $weight-normal;
  line-height: 1.3;
  font-size: 0.875rem;
  padding: 1rem;

  .eb-js-ellipsis {
    height: 2.5rem;
    overflow: hidden;
  }

  .price {
    text-align: right;
    font-weight: $weight-black;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-content: center;

    .normal {
      color: $grey-dark;
      text-decoration: line-through;
      font-size: 0.8rem;
      @include tablet() {
        font-size: $size-small;
      }
    }

    .sale {
      color: $red-dark;
      @include mobile() {
        font-size: 0.9rem;
      }
    }
  }

  .time {
    font-size: 0.7rem;
    //color: $grey-dark;
    line-height: 1.4;
    font-weight: $weight-medium;
    @include tablet() {
      font-size: $size-small;
    }
  }

  .menus {
    font-size: 0.7rem;
    color: $primary;
    text-align: right;
    font-weight: $weight-medium;
    text-transform: uppercase;

    @include tablet() {
      font-size: $size-small;
    }

    .is-flex-mid-col {
      justify-content: flex-start;
    }

    .menu {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .column {
    padding-bottom: 0;
  }

  .price {
    font-size: 1rem;
  }

  .show-menu__item {
    margin-bottom: 0;
  }
}

.map-marker--filtered {
  background: $grey-dark;
}

.map-board--guest {
  text-align: center;
  width: 15rem;
  margin-left: -7.7rem;

  .title {
    padding: 0.875rem;
    margin: 0;
    border: 0;
    font-weight: $weight-normal;
    text-transform: none;
    font-size: 1rem;
  }

  .map-board__content {
    display: none;
  }

  .icon {
    display: none;
  }
}
