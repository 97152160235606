$icomoon-font-family: 'earlybird-icons' !default;
$icomoon-font-path: '../../assets/fonts' !default;

$eb-takeaway1: '\e929';
$eb-restaurants: '\e92b';
$eb-bars: '\e92e';
$eb-icon-explore-black: '\e932';
$eb-takeaway: '\e900';
$eb-f5: '\e901';
$eb-f4: '\e902';
$eb-f2: '\e903';
$eb-f3: '\e904';
$eb-f1: '\e905';
$eb-call: '\e906';
$eb-chat: '\e907';
$eb-check: '\e908';
$eb-check-mark: '\e909';
$eb-close: '\e90a';
$eb-rr: '\e90b';
$eb-rl: '\e90c';
$eb-ru: '\e90d';
$eb-rd: '\e90e';
$eb-filter: '\e90f';
$eb-insta: '\e910';
$eb-mail1: '\e911';
$eb-mail2: '\e912';
$eb-apple: '\e913';
$eb-home: '\e914';
$eb-info: '\e915';
$eb-gift: '\e916';
$eb-fork: '\e917';
$eb-lock: '\e918';
$eb-file: '\e919';
$eb-faq: '\e91a';
$eb-contacts: '\e91b';
$eb-news: '\e91c';
$eb-map1: '\e91d';
$eb-list: '\e91e';
$eb-glass: '\e91f';
$eb-fb: '\e920';
$eb-map2: '\e921';
$eb-burger: '\e922';
$eb-play: '\e923';
$eb-gplay: '\e924';
$eb-pb: '\e925';
$eb-pl: '\e926';
$eb-pt: '\e927';
$eb-pr: '\e928';
$eb-checkbox-checked: '\ea52';
$eb-checkbox-unchecked: '\ea53';
