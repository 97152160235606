body {
  padding-bottom: 70px;
}

// create fixed position bottom nav bar
.bottom-nav-bar {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  display: flex;
  justify-content: space-around;
  align-items: center;
  z-index: 9;
  box-shadow: 0px -1px 1px rgba(0, 0, 0, 0.2);
  line-height: 2;
  padding-bottom: 10px;
}

.bottom-nav-bar__label {
  color: #000;
  font-size: 12px;
  font-weight: 400;
}

.bottom-nav-bar__item {
  opacity: 0.5;
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 10px 0;
}

.bottom-nav-bar__item--active {
  opacity: 1;

  .bottom-nav-bar__label {
    font-weight: 600;
  }
}

.bottom-nav-bar__icon {
  display: block;
  // icon font size for bottom nav bar
  font-size: 24px;
  color: #000;
  // remove line height for icon font to not affect vertical alignment
  line-height: 0;
}
