@import '../../../styles/all_vars';

$padding: 1.3rem;
$filterCH-box-shadow: 0px 0px 50px 0px rgba(19, 40, 76, 0.4);
$marginTop: 1.4rem;

.filters .field .dropdown .dropdown-menu {
  &.is-checklist {
    margin: 0;
    padding: 0;
    transition: none;

    @include tablet() {
      padding: 0 $size-large $size-large;
    }

    @include desktop() {
      padding: 0 $size-xlarge $size-xlarge;
    }

    .dropdown-content {
      margin-top: $marginTop;
      border: 0;
      //border-radius: $radius-medium;
      //box-shadow: $box-shadow;
    }

    .control {
      height: auto;
    }

    .grid-list,
    .dropdown-footer {
      padding: $padding;
    }

    .dropdown-header {
      font-size: $size-normal - 0.1rem;
    }

    .grid-list {
      .item {
        margin-bottom: 0.5rem;
        width: (100% * 0.5);

        @include tablet() {
          width: (100% * 0.25);
        }

        @include widescreen() {
          width: (100% * 0.2);
        }
      }

      .control,
      .label-cb {
        font-weight: $weight-normal;
        //font-weight: 900;
        font-size: 0.75rem;
        color: $black;

        white-space: nowrap;
        overflow: hidden;
        position: relative;
        text-overflow: ellipsis;
        padding-top: 2px;
        padding-bottom: 2px;

        @include tablet() {
          font-size: $size-normal;
        }
      }
    }

    .dropdown-footer {
      border-top: 0;
      padding-top: 0;

      @include tablet() {
        border-top: 1px solid $grey;
        padding-top: 1.3rem;
      }
    }

    .buttons {
      justify-content: space-between;
      padding: 0;

      .button {
        border-radius: $radius-large;
        text-transform: uppercase;
        width: 100%;
        height: 40px;
        font-size: 0.75rem;
        margin-right: 0;
        padding: 0;

        @include tablet() {
          padding: 1rem;
          height: auto;
          width: auto;
          min-width: 11rem;
          font-size: $size-small;
        }

        &.is-danger {
          color: $red-invert;
        }
      }
    }
  }
}

.filter-checkbox-column {
  .dropdown-content-arrow {
    display: none;
    //position: absolute;
    //right: 50%;
    //z-index: 10;
    //bottom: -($marginTop - 0.5rem);
    //top: -20px;
    //transform: rotateX(180deg);
    //
    //@include tablet() {
    //  top: 63px;
    //  bottom: -($marginTop - 0.9rem);
    //  transform: rotateX(0deg);
    //}
  }

  .dropdown-box {
    //margin-top: ($marginTop - 0.39rem);
    margin-top: $marginTop;

    .box {
      padding: 0;
      box-shadow: $filterCH-box-shadow;
    }
  }

  .dropdown-header {
    .eb-close {
      padding: $padding $padding 0;
      cursor: pointer;
      font-size: $size-xsmall;
      max-width: 50px;
      position: absolute;
      top: 0;
    }
  }

  .dropdown-trigger {
    z-index: 1;
    position: relative;
  }

  .dropdown-menu {
    padding: 0 !important;
  }

  .item--odd {
    @include mobile() {
      padding-left: 0.65rem;
      white-space: nowrap;
    }
  }
}

.filters .field {
  .dropdown-content-arrow {
    display: none;
  }

  &.is-active {
    .dropdown-content-arrow {
      display: block;
    }
  }
}

.dropdown-header__title {
  padding: 16px 0;
  text-align: center;
  text-transform: uppercase;
  font-weight: $weight-bold;
  font-size: 15px;

  @include tablet() {
    font-size: 22px;
  }
}
