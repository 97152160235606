@use "sass:math";

@import 'styles/all_vars';

.int-links {
  margin: 1rem 0;
  display: block;

  @include tablet() {
    margin-right: -5px;
    margin-left: -5px;
    display: flex;
    flex-direction: row;
  }
}

.int-links__content {
  position: relative;
  max-width: 100%;
  min-height: 100%;
  width: 100%;
  height: auto;

  margin-left: 0;
  margin-right: 0;
  padding-bottom: 0.6rem;
  padding-top: 0.6rem;

  &:not(:last-child) {
    border-bottom: 2px solid $grey;
  }

  @include tablet() {
    margin-left: 5px;
    margin-right: 5px;
    width: math.div(100%, 3);
    margin-bottom: 1.5rem;
    padding-bottom: 0;
    padding-top: 0;
    //height: 180px;
  }

  @include desktop() {
    //height: 230px;
  }

  a {
    display: flex;
    align-items: center;

    @include tablet() {
      display: block;
    }

    .image {
      width: 40%;
      border-radius: $radius-large;
      overflow: hidden;

      @include tablet() {
        width: 100%;
      }
    }

    .int-links__link {
      width: 60%;
      @include tablet() {
        width: 100%;
      }
    }
  }
}

.int-links__link {
  position: relative;

  text-align: left;
  padding: 10px;
  z-index: 1;
  background: $white;

  @include tablet() {
    position: absolute;
    background: hsla(0, 0%, 100%, 0.8);
    max-width: 350px;
    left: 0;
    right: 0;
    bottom: 30px;
  }
}

.int-links__text {
  text-decoration: none !important;
  color: #000;
  font-weight: 700;
  line-height: 1.2 !important;
  font-size: 1rem;

  @include tablet() {
    font-size: 16px;
  }
}

.int-links__label {
  font-weight: 700;
  margin-bottom: 1rem;
}

.mobile {
  .int-links__label {
    display: none;
  }
}

.int-links__content__img {
  display: block;
  width: 100%;
}
