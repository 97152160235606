@import 'styles/all_vars';

.overlay-wrap {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.overlay {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 2;
  padding: 1rem;
  cursor: pointer;
}

.overlay__bg {
  position: absolute;
  z-index: 2;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: #000;
  opacity: 0.6;
}

.overlay__content {
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  z-index: 5;
  color: $white;
  text-align: center;
  align-content: center;
  justify-content: center;
  font-size: $size-xsmall;
  padding: 0.1rem;
  @include tablet() {
    font-size: $size-small;
  }
}

.overlay__line {
  background-color: $red;
  display: block;
  height: 2px;
  width: 160px;
  margin: 1rem auto;
}

.overlay__title {
  font-weight: $weight-bold;
  font-size: $size-medium;
  text-transform: uppercase;
  @include tablet() {
    font-size: $size-large;
  }
}

.overlay__subtitle {
  text-transform: uppercase;
}

//
// Red circle for filter overlay
//

.foverlay {
  position: absolute;
  left: 50%;
  top: 50%;
  bottom: 0;

  font-size: 0.9rem;
  line-height: 1.2;

  $foverlaySize: 175px;
  width: $foverlaySize;
  height: $foverlaySize;

  $foverlayComputedPos: -($foverlaySize * 0.5);
  margin-left: $foverlayComputedPos;
  margin-top: $foverlayComputedPos;
}

.foverlay__circle {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;

  border-radius: $radius-rounded;
  font-weight: $weight-medium;
  height: 100%;
  width: 100%;
  background: $red;
  color: $white;
}

.foverlay__title {
  text-transform: uppercase;
}

.foverlay__list {
  text-transform: capitalize;
  text-align: left;
  list-style: disc;
  line-height: 1.2;
  margin-top: 0.35rem;
}
