@import '../overrides/utilities/all';

.img-slider__close {
  position: fixed;
  z-index: 3;

  &.eb-circle {
    //font-size: $size-6 !important;
    //padding: 0.6rem 0.8rem;
    margin-right: 0;
    margin-top: 1rem;
  }
}

.img-slider {
  position: relative;
  //box-shadow: $box-shadow;
  min-height: 20rem;
  border-radius: $radius-large;
  overflow: hidden;
  //background-color: $black;

  @include tablet() {
    //background-color: $white;
  }

  .swiper-slide {
    box-shadow: none;
  }
}

.img-slider__wrap {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.img-slider__img {
  display: block;
  position: relative;

  img {
    display: block;
    height: auto;
    width: auto;
    margin: 0 auto;
    border-radius: $radius-large;
  }
}

.img-slider__nav {
  position: absolute;
  width: 100px;
  margin-top: 0 !important;
  margin-right: -1rem !important;
  margin-left: -1rem !important;
  top: 0;
  bottom: 0;
  height: 100%;
  display: flex;
  align-items: center;
  user-select: none;

  @include tablet() {
    width: 140px;
  }

  &.is-left {
    left: 0 !important;
    justify-content: flex-start;
  }

  &.is-right {
    right: 0 !important;
    justify-content: flex-end;
  }

  .button.is-circle {
    position: relative !important;
    top: 0;
    right: 0;
    margin: 0;
  }
}

.img-slider {
  .swiper-slide {
    border-radius: 0;
  }
}
