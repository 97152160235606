@import 'styles/all_vars';

.info-view-menu {
  &__item {
    position: relative;

    &:not(:last-child) {
      margin-bottom: 3rem;
    }
  }

  h2 {
    text-transform: uppercase;
    font-size: 0.9rem;
    @include tablet() {
      font-size: 1.1rem;
    }
  }

  .label {
    font-size: $size-small;
    text-transform: uppercase;
    margin-bottom: 0;
    letter-spacing: 1px;
    font-weight: $weight-black;
  }

  .subtitle,
  p {
    font-size: $size-medium;
  }

  &__content {
    font-size: $size-medium;
    display: none;

    hr {
      background-color: $grey;
      @include tablet() {
        margin-right: -1.125rem;
        margin-left: -1.125rem;
      }

      @include desktop() {
        margin-right: -2.125rem;
        margin-left: -2.125rem;
      }
    }
  }

  .subtitle {
    margin-bottom: 2rem;
    margin-top: 1rem;
    text-align: center;
    color: $red-dark;
  }

  .box {
    padding: 0;

    &:not(:last-child) {
      margin-bottom: 2.6rem;
    }

    //padding: 1rem;
    @include tablet() {
      padding-top: 0.85rem;
      //padding-bottom: 2rem;
    }

    .content {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding-top: 0;
      border-top: 2px solid transparent;

      &.is-active {
        position: relative;
        bottom: 0;
        top: 0;
        //padding-top: 3rem;
        border-top-color: $grey;

        //@include tablet() {
        padding: 1.125rem;
        //}

        @include desktop() {
          padding: 2.125rem;
        }

        //.button.is-circle {
        //  margin-top: -1.525rem;
        //}
      }
    }
  }

  .price,
  .info {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
  }

  .price {
    text-align: right;
    justify-content: flex-end;

    .tag {
      font-weight: $weight-black;
      text-transform: uppercase;
      font-size: 0.8rem;

      line-height: 1;

      small {
        font-size: 0.75rem;
        display: block;
        text-space: 0;
        @include tablet() {
          font-size: $size-small;
        }
      }
    }

    .is-normal {
      font-weight: $weight-bold;
      color: $grey-dark;
      text-decoration: line-through;
      font-size: 0.8rem;
      @include tablet() {
        font-size: $size-medium;
      }

      small {
        font-size: 0.625rem;
        @include tablet() {
          font-size: $size-small;
        }
      }
    }

    .is-sale {
      background-color: $red-light;
      color: $red-dark;
    }
  }
}

.button {
  &.is-circle {
    $size: 2rem;
    width: $size;
    height: $size;
    min-width: $size;
    min-height: $size;
    max-width: $size;
    max-height: $size;

    border: 0;
    z-index: 2;
    border-radius: $radius-rounded;
    padding: 0;
    font-size: $size-8;
    color: $white;
    background-color: $red-dark;
    box-shadow: $box-shadow-red;

    position: absolute;
    top: 0;
    right: 50%;
    margin-top: -1.1rem;
    margin-bottom: 0;
    margin-right: -1.8rem;

    @include tablet() {
      font-size: $size-6;
      margin-top: -1.5rem;

      $size: 3rem;
      width: $size;
      height: $size;
      min-width: $size;
      min-height: $size;
      max-width: $size;
      max-height: $size;
    }
  }
}

.is-active {
  .info-view-menu__content {
    display: block;
  }
}

.info-view-menu__content {
  .label {
    font-size: 0.9rem;
    color: $black;
  }

  p {
    font-size: 0.9005rem;
    line-height: 1.4;
  }
}

.info-view-menu__obs {
  color: $red;
  font-weight: $weight-bold;
  text-align: center;
  line-height: 1.25;
  margin-top: 1rem;
}

p.info-view-menu__obs {
  font-size: 0.9rem;
}

.menu-product-page {
  display: block;
  @include tablet() {
    display: none;
  }
}

.info-view-menu__top {
  margin-bottom: 0 !important;
  //@include tablet() {
  padding-left: 1.125rem;
  padding-right: 1.125rem;
  //}

  @include tablet() {
    padding-left: 2.125rem;
    padding-right: 2.125rem;
  }
}
