@import 'styles/all_vars';

.headroom-wrapper:not(.headroom--disabled) {
  //max-height: 9.125rem;
  //min-height: 9.125rem;

  //@include tablet() {
  max-height: 4.0625rem;
  min-height: 4.0625rem;
  //}
}

.filters .field.is-active {
  z-index: 11;
}

.filters__item--left {
  width: 100%;
  @include tablet() {
    width: 96%;
  }
}

.filters__item--right {
  width: 4%;
  display: none;
  justify-content: center;
  align-items: center;
  padding-top: 1rem;
  padding-left: 1rem;

  @include tablet() {
    display: flex;
  }

  .filter-head__filter {
    position: relative;
    margin-top: 0;
    @include tablet() {
      margin-top: 61px;
    }

    .dropdown .button {
      width: 15px;
      height: 22px;
    }

    .eb-sort {
      position: absolute;
      left: 5px;
      right: 0;
      top: 0;
      bottom: 0;
    }
  }
}

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: 100%;
  height: 100%;
  padding: 10px 20px;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 49px;
  left: -2px;
  box-sizing: content-box;
  width: 100%;
  border: 2px solid $grey;
  border-top: 0;
  background-color: #fff;
  font-size: 0.875rem;
  border-bottom-left-radius: $radius-large;
  border-bottom-right-radius: $radius-large;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 0.375rem 1rem;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #f5f5f5;
}

.filters--search-pop {
  display: flex;
  align-content: center;
  justify-content: center;
  height: 100%;
  padding: 2rem 1rem;

  .filters {
    width: 100%;
  }

  .box {
    padding: 1rem;
  }

  .eb-close {
    padding-bottom: 1rem;
    cursor: pointer;
    font-size: 0.75rem;
    max-width: 50px;
  }

  .box-header {
  }
}

.pop-page.search-popup {
  padding-top: 5rem;
}

#city-selection.switch-type {
  overflow: hidden;
  margin-bottom: 0;
  border-radius: $radius-large $radius-large 0 0;
  width: 520px;
  max-width: 100%;

  .button-slider {
    border-radius: 0;
    background-color: $red;
    color: $white;
    right: -1px;
    top: -1px;
    bottom: -1px;
    left: 0;
  }

  .button-slider-border {
    position: absolute;
    right: 0;
    width: 1px;
    background: #0d1121;
    height: 70%;
    top: 10px;
    bottom: 0;
  }

  &.is-switcher .button.is-selected {
    color: $white;
    border-radius: 0;
    background-color: $red;
  }

  .button {
    padding: 1.1rem 0;

    &.switch-type__first {
      border-bottom-left-radius: 0;
    }

    &.switch-type__last {
      border-bottom-right-radius: 0;
    }
  }
}

#city-selection.switch-city {
  width: 265px;
  border-radius: $radius-large;

  .button-slider {
    border-radius: $radius-large;
  }

  .button {
    padding: 1.1rem;
  }
}

.sort-filter-box {
  .box {
    padding: 0;
  }

  .grid-list {
    padding: 1.3rem;
  }

  .label-cb {
    font-weight: 400;
    font-size: 0.75rem;
    color: #000;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    text-overflow: ellipsis;
    padding-top: 2px;
    padding-bottom: 2px;
  }

  .dropdown-footer {
    padding: 1.3rem;
  }
}

.filter-head__filter {
  .sort-filter-box {
    .dropdown .button {
      border-radius: 10px;
      text-transform: uppercase;
      width: 100%;
      height: 40px;
      font-size: 0.75rem;
      margin-right: 0;
      padding: 0;

      &.is-danger {
        color: #fff;
      }
    }
  }
}
