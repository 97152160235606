@import 'styles/all_vars.scss';

$mHeadIconSize: 2rem;
$mHeadNavSpacing: 1rem;
.m-head {
  position: relative;
  display: block;
  margin-right: -1.5rem;
  margin-left: -1.5rem;
  margin-bottom: 1.5rem;
  @include tablet() {
    display: none;
  }
}

.m-head__overlay {
  background-image: linear-gradient(0, rgba(0, 0, 0, 0) 0%, $primary 100%);
  opacity: 0.502;
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transition: all 300ms;
}

.head__overlay--blue {
  background-color: $primary;
  opacity: 0.502;
  transition: all 100ms;
}

.galleri-block {
  color: $white;
  font-size: $size-small;
  font-weight: $weight-black;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  height: 100%;

  svg {
    height: $mHeadIconSize - 0.5rem;
    width: $mHeadIconSize - 0.5rem;
    margin-right: 0.3rem;
  }
}

.m-head-box {
  $mHeadBoxHeight: 6.5rem;
  position: relative;
  z-index: 3;
  //position: absolute;
  //left: 0;
  //right: 0;
  //bottom: 0;
  //margin-top: -($mHeadBoxHeight / 2);
  //height: $mHeadBoxHeight;
  //padding-left: $mHeadNavSpacing;
  //padding-right: $mHeadNavSpacing;

  .box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    text-align: center;
    font-size: $size-small;
    font-weight: $weight-black;
    line-height: 1.5;
    padding: 1rem;
    border-radius: $radius-large;
  }

  .label {
    font-weight: $weight-black;
  }
}

.m-content {
  position: relative;
  z-index: 2;
  line-height: 1.3;
  //color: $grey-dark;

  display: block;
  @include tablet() {
    display: none;
  }

  .m-head-box {
    margin-bottom: 10px;

    .box {
      border-radius: $radius-large;
    }
  }
}

.m-content__address {
  margin-bottom: 1rem;

  .title {
    color: $grey-dark;
    font-size: $size-small;
    font-weight: $weight-black;
  }

  .eb {
    color: $red-dark !important;
  }
}

.m-buttons {
  position: relative;
  text-align: center;
  z-index: 1;
  //margin-bottom: 1rem;
  border-radius: $radius-large;
  overflow: hidden;
  background-color: $blue-lightest;

  a {
    display: block;
    font-weight: $weight-medium;
    font-size: 0.8rem;
    letter-spacing: 1px;
    background-color: $blue-lightest;
    color: $grey-dark;
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
    z-index: 2;
    text-decoration: none;

    &.is-active {
      background-color: $red-dark;
      color: $white;
      //border-radius: $radius-large;
      z-index: 3;
    }
  }
}

.m-galleri {
  z-index: 12;
  .img-slider {
    border-radius: 0;
    min-height: 100%;
  }

  .img-slider__img {
    img {
      border-radius: 0;
      //max-height: none;
      //
      //@include tablet() {
      //  max-height: 500px;
      //}
    }
  }

  .pop-page-outer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
    overflow: hidden;
  }

  .img-slider__nav {
    margin-right: 0.2rem !important;
    margin-left: 0.2rem !important;
    position: fixed;
    z-index: 20;
    @include tablet() {
      position: absolute;
    }
  }
}

.info-view-menu {
  margin-top: 2.5rem;
  @include tablet() {
    margin-top: 0;
  }
}

.m-head-fixed {
  padding-top: $navbar-height;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.m-content--parallax {
  //margin-top: 66.25%;
  //padding-top: $navbar-height;
}

.m-head__nav {
  top: 0;
  position: absolute;
  z-index: 3;
  display: flex;
  transform: translate3d(0, 0, 0);

  a {
    display: block;
  }

  .eb-circle {
    background-color: $white;
    color: $black;
    font-size: $size-small;
    line-height: 1.5;
    height: $mHeadIconSize;
    width: $mHeadIconSize;
    padding: 0.45rem 0.55rem;
  }

  .navbar-burger {
    display: none;
    height: 2rem;
    margin-right: -1rem;

    span {
      color: #fff;
    }
  }
}

.m-head__nav--left {
  left: 0;
  margin-left: $mHeadNavSpacing;
  justify-content: flex-start;
}

.m-head__nav--right {
  right: 0;
  margin-right: $mHeadNavSpacing;
  justify-content: flex-end;
}

.m-nav_contain {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 4;
  height: $navbar-height;
  overflow: hidden;
  margin-top: $navbar-height + ($mHeadIconSize * 0.5);
  display: block;
  @include tablet() {
    display: none;
  }

  .m-head__nav {
    //margin-top: $navbar-height + ($mHeadIconSize / 2);
  }
}

.m-nav_contain--pin {
  position: fixed;
  margin: 0;

  .m-head__nav {
    padding-top: (($navbar-height * 0.5) - ($mHeadIconSize * 0.5));
    //margin-top: (($navbar-height / 2) - ($mHeadIconSize / 2));
  }

  .galleri-block {
    display: none;
  }

  .navbar-burger {
    display: block;
  }
}

.m-nav_contain--colored {
  background: $primary;
}

.n-head__name {
  color: $white;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 90%;
  padding: 0 2rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  //font-weight: $weight-bold;

  // hide and later will show it while scrolling
  transform: translate3d(0, -1000px, 0);
  transition: all 100ms ease-in-out;

  .title {
    font-size: $size-medium;
    margin-bottom: 0;
  }

  .subtitle {
    font-size: $size-small;
    font-weight: $weight-normal;
    color: $white;
  }
}
